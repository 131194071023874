<template>
  <v-row
    class="my-4"
    justify="center"
  >
    <v-col>
      <div v-if="distribuidores.length">
        <paginate
          name="products"
          :list="distribuidores"
          :per="perPage"
          class="pl-0"
        >
          <v-row justify="center">

            <v-col
              v-for="distribuidor in paginated('products')" 
              :key="distribuidor.id"
              md="4"
            >
              <v-card
                max-width="300"
                class="mx-4 my-4"
              >
                <v-card-text>
                  <p class="display-1 text--primary">
                    {{ distribuidor.nombre_distribuidor }}
                  </p>
                  <div>
                    Stripe id: {{ distribuidor.id_stripe }}<br>
                    Contacto: {{ fullName(distribuidor) }}<br>
                    RFC: {{ defaultTaxId(distribuidor) }}<br>
                    Empleados: {{ distribuidor.usuarios_autorizados.length }}
                  </div>
                </v-card-text>
                <!-- <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                  >
                    Editar
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                  >
                    Eliminar
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </paginate>
        <paginate-links
          for="products"
          :async="true"
          :limit="5"
          :hide-single-page="true"
          :classes="{
            'ul': 'pagination',
            'li': 'page-item',
            'li > a': 'page-link'
          }"
        >
        </paginate-links>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    distribuidores: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      paginate: ['products'],
      perPage: 3,
    }
  },
  methods: {
    fullName(distributor) {
      const name = distributor.datos_contacto.primer_nombre
      const secondName = distributor.datos_contacto.segundo_nombre ? distributor.datos_contacto.segundo_nombre : ""
      const middleName = distributor.datos_contacto.apellido_paterno
      const lastName = distributor.datos_contacto.apellido_materno ? distributor.datos_contacto.apellido_materno : ""
      return `${name} ${secondName} ${middleName} ${lastName}`
    },
    defaultTaxId(distributor) {
      const defaultDf = distributor.datos_fiscales.find(df => df.predeterminado)
      return defaultDf ? defaultDf.tax_id : ""
    }
  }
}
</script>

<style>

</style>